import React from 'react';
import { Col, Form, InputNumber, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { formatTaskQueries, deformatTaskQueries } from './Common/Google/utils';
import BaseService from './Common/BaseService';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import AdvancedParametersPanel from './Common/AdvancedParametersPanel';
import QueriesFormItems, { getDefaultIsCustomCategories, getDefaultIsCustomLocations } from './Common/Google/QueriesFormItems';
import RegionSelect from './Common/Google/RegionSelect';
import OrganizationsPerQueryInput from './Common/Google/OrganizationsPerQueryInput';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';
import { isPaidUser } from '../../utils/utils';
import { enrichmentFields } from '../../shared/constants/googleMaps.constants';
import { validateCategoryLocationExpanded } from '../../utils/validation';

const CUSTOM_QUERIES_KEY = 'photos/customQueries';
const { Option } = Select;

export default function GoogleMapsPhotos() {
  const location = useLocation();
  const { query: urlQuery, gl: urlRegion } = queryString.parse(location.search);
  const taskExtraDefaultParams = {
    queries: urlQuery ? urlQuery : '',
    categories: [],
    locations: [],

    customCategories: '',
    customLocations: '',

    language: 'en',
    region: (urlRegion && urlRegion !== 'undefined') ? urlRegion : undefined,

    photosPerOrganizationLimit: 250,
    organizationsPerQueryLimit: 1,

    UISettings: {
      isCustomQueries: urlQuery ? true :(localStorage.getItem(CUSTOM_QUERIES_KEY) ? localStorage.getItem(CUSTOM_QUERIES_KEY) === '1' : true),
      isCustomCategories: getDefaultIsCustomCategories(),
      isCustomLocations: getDefaultIsCustomLocations(),
    },
  };

  const { t } = useTranslation();
  const paidUser = isPaidUser();

  function formatTask(task) {
    const { enrich, UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

    if (enrich) {
      task.organizationsPerQueryLimit = 1;
      task.region = undefined;
    }

    return formatTaskQueries({ ...task }, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  function deformatTask(task) {
    const { UISettings = {} } = task;
    const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;
    return deformatTaskQueries(task, isCustomQueries, isCustomCategories, isCustomLocations);
  }

  const labelWithTooltip = (
    <PremiumFeatureTooltip isPaidUser={paidUser}>
      <span>{t('title.filtering')}</span>
    </PremiumFeatureTooltip>
  );

  return (
    <BaseService
      title='Google Maps Photos Scraper'
      subTitle='Returns photos from places on Google Maps'
      serviceName='google_maps_photos_service_v3'
      unitName='photo'
      learnMoreUrl='https://outscraper.com/google-maps-photos-scraper'
      apiTag='Google/paths/~1maps~1photos-v3/get'
      taskExtraDefaultParams={taskExtraDefaultParams}
      validateTask={validateCategoryLocationExpanded}
      formatTask={formatTask}
      deformatTask={deformatTask}
      taskUpdateAfterSubmit={{ queries: '', categories: [], customCategories: '', input_file: null, enrich: false, tags: [] }}
      FormBody={({ task, updateTask, invalidFields }) => {
        const { UISettings = {} } = task;
        const { isCustomQueries, isCustomCategories, isCustomLocations } = UISettings;

        function setIsCustomCategories(isCustomCategories) {
          updateTask({ UISettings: { ...UISettings, isCustomCategories } });
        }

        function setIsCustomLocations(isCustomLocations) {
          updateTask({ UISettings: { ...UISettings, isCustomLocations } });
        }

        return <>
          <QueriesFormItems
            task={task}
            onUpdate={updateTask}
            isCustomQueries={isCustomQueries}
            isCustomCategories={isCustomCategories}
            isCustomLocations={isCustomLocations}
            onIsCustomCategoriesChange={setIsCustomCategories}
            onIsCustomLocationsChange={setIsCustomLocations}
            customQueriesKey={CUSTOM_QUERIES_KEY}
            enrichmentFields={enrichmentFields}
            invalidFields={invalidFields}
          />
        </>;
      }}
      FormBodyExtra={({ task, updateTask }) => {
        const { region, enrich, organizationsPerQueryLimit, photosPerOrganizationLimit, tag = '', UISettings = {} } = task;
        const { isCustomQueries, isCustomLocations } = UISettings;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24}>
            <Form.Item label={t('title.photosPerPlace')} wrapperCol={{ xs: { span: 24 }, lg: { span: 4 } }}>
              <Space align='center' className='first-space-item-full'>
                <InputNumber
                  className='w-100'
                  min={1}
                  step={100}
                  value={photosPerOrganizationLimit}
                  onChange={(v) => updateTask({ photosPerOrganizationLimit: parseInt(v) || 1 })}
                />
                <InfoTooltip title='Parameter specifies the limit of photos for one organization.'/>
              </Space>
            </Form.Item>
          </Col>

          <Col xs={24}>
            {!enrich &&
                <Form.Item
                  label= {t('title.organizationsPerQueryLimit')}
                  wrapperCol={{
                    xs: { span: 24 },
                    lg: { span: 4 },
                  }}
                >
                  <OrganizationsPerQueryInput
                    value={organizationsPerQueryLimit}
                    onChange={(v) => updateTask({ organizationsPerQueryLimit: v })}
                  />
                </Form.Item>}
          </Col>

          <Col xs={24} lg={4}>
            <Form.Item label={labelWithTooltip}>
              <Select
                allowClear
                autoFocus
                showSearch
                placeholder={t('title.any')}
                value={tag}
                disabled={!paidUser}
                onChange={(v) => updateTask({ tag: v })}
              >
                <Option key='all' value=''>{t('title.any')}</Option>
                <Option key='latest' value='latest'>Latest</Option>
                <Option key='menu' value='menu'>Menu</Option>
                <Option key='by_owner' value='by_owner'>By owner</Option>
              </Select>
            </Form.Item>
          </Col>

          {(isCustomLocations || isCustomQueries) && !enrich &&
            <AdvancedParametersPanel>
              <Col xs={24}>
                <Form.Item
                  label={t('title.countryCustom')}
                  wrapperCol={{
                    xs: { span: 24 },
                    lg: { span: 4 },
                  }}
                >
                  <RegionSelect value={region} onChange={(v) => updateTask({ region: v })} />
                </Form.Item>
              </Col>

            </AdvancedParametersPanel>
          }
        </Space>;
      }}
    />
  );
}

GoogleMapsPhotos.propTypes = {};
