import React from 'react';
import { Col, Form, InputNumber, Space } from 'antd';

import SimpleService from '../Common/SimpleService';
import PreferredContactsItem from './../Common/PreferredContactsItem';

export default function HubSpotCompanyContactsFinder() {
  return (
    <SimpleService
      beta
      unitName='email'
      title='HubSpot Company Contacts Finder'
      subTitle='Find company contacts'
      serviceName='hubspot_contact_finder_service'
      startTaskButtonTitle='Find Contacts'
      limitType={null}
      showParams={false}
      learnMoreUrl='https://outscraper.com/hubspot-enrichments/'
      requiredIntegratoion={'HubSpot'}
      defaultParams={{ queries: 'contacts', integration_data_path: 'hubspot.refresh_token', limit_per_company: 3 }}
      ExtraItems={({ updateTask, task }) => {
        const { limit_per_company } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <PreferredContactsItem value={task} onChange={updateTask} isPaidUser />
          </Col>

          <Col xs={24}>
            <Form.Item
              label='Number of contacts per one company'
              wrapperCol={{
                xs: { span: 24 },
                lg: { span: 6 },
                xl: { span: 4 },
              }}
            >
              <InputNumber
                className='w-100'
                min={1}
                step={1}
                max={1000}
                value={limit_per_company}
                onChange={(v) => updateTask({ limit_per_company: parseInt(v) || 1 })}
              />
            </Form.Item>
          </Col>

          <p className='main-text'>* The service will try to find and add contact details for companies without associated contacts.</p>
        </Space>;
      }}
    />
  );
}
