import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Select, Space, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import AdvancedParametersPanel from '../../../Common/AdvancedParametersPanel';
import { isAdvancedUser } from '../../../../../utils/localDB';
import Filters from '../../../Common/Google/Filters';
import LanguageSelect from '../../../Common/Google/LanguageSelect';
import OrganizationsPerQueryInput from '../../../Common/Google/OrganizationsPerQueryInput';
import PremiumFeatureTooltip from '../../../../../shared/Components/PremiumFeatureTooltip';
import InfoTooltip from '../../../../../shared/Components/InfoTooltip';
import { onSetDefaultLanguage } from '../../../../../utils/defaultProps';
import { QuestionCircle } from '../../../../../assets/icons';

const { Option } = Select;

export const AdvancedParameters = ({ task, updateTask, isPaidUser }) => {
  const { t } = useTranslation();
  const {
    language, region, organizationsPerQueryLimit, skip_places,
    dropDuplicates, enrich, useZipCodes, UISettings = {}, ignoreWithoutEmails,
  } = task;
  const { isCustomLocations } = UISettings;
  const isEnLanguage = language === 'en';
  const supportsZipCodes = useSelector(state => state.queriesReducer.countrySupportsZipCodes)[region];

  function onSetLanguage(value) {
    updateTask({ language: value });
    onSetDefaultLanguage(value);
  }

  function onChangeDropDuplicates() {
    const value = !dropDuplicates;
    updateTask({ dropDuplicates: value });
    localStorage.setItem('dropDuplicates', value ? '1' : '0');
  }

  function handleUseZipCodesChange() {
    updateTask({ useZipCodes: !useZipCodes });
  }

  function onChangeOrganizationsPerQueryInput(value) {
    updateTask({ organizationsPerQueryLimit: value });
  }

  const skipLabelWithTooltip = (
    <PremiumFeatureTooltip isPaidUser={isPaidUser}>
      <span>{t('title.Skip', 'Skip')}</span>
    </PremiumFeatureTooltip>
  );

  const limitLabelWithTooltip = (
    <PremiumFeatureTooltip isPaidUser={isPaidUser}>
      <span>{t('title.organizationsPerQueryLimit', 'Places per one query search')}</span>
    </PremiumFeatureTooltip>
  );

  return  (
    <AdvancedParametersPanel header={<Trans i18nKey='action.advancedParameters'>Advanced parameters (<strong>filters</strong>, <strong>language</strong>, limit per query, etc.)</Trans>}>
      {!enrich &&
          <Col xs={24}>
            <Form.Item
              label={<Trans i18nKey='title.filters'>Filters (return only relevant results)</Trans>}
              tooltip={{ title: <Trans i18nKey='description.filters'>
                        Sometimes Google adds other categories to your search.
                        Filters allow you to ignore such categories and pay only for the matched items.
                        For example, when you search by category &quot;restaurant&quot; you might see similar categories like bars, pizza, etc.
                        Using `subtypes` contains &quot;restaurant&quot; filter will return only the restaurants and ignore other categories.
                <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-data-scraper-filters/'> <Trans i18nKey='action.learnMore'>Learn more</Trans></a>
                <br/><br/>
                        If the amount of ignored rows are less than 5,000% of what was actually extracted, you won&apos;t be billed for ignored records.
                <br/><br/>
                        When using filters, the estimate of the results will be very approximate.
                <br/><br/>
                        It might drastically increase the execution time, especially when using the total limit.
              </Trans>, icon: <QuestionCircle /> }}
            >
              <Tooltip title={isEnLanguage || isAdvancedUser ? '' : t('description.languageDisabled', 'Works only for EN language')}>
                <Filters
                  value={task.filters}
                  exactMatch={task.exactMatch}
                  onChange={updateTask}
                  disabled={!isEnLanguage && !isAdvancedUser}
                  region={region}
                  isPaidUser={isPaidUser}
                />
                <a className='hintText' target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-data-scraper-filters/'>
                  <Trans i18nKey='description.howToUseFilters' />?
                </a>
              </Tooltip>
            </Form.Item>
          </Col>
      }

      <Space direction='vertical' size={16} className='w-100'>
        <Col xs={24} lg={6} xl={4}>
          <Form.Item label={t('title.language')}>
            <LanguageSelect onChange={onSetLanguage} value={language}/>
          </Form.Item>
        </Col>
        {!enrich && <Space size={16} direction='vertical' className='w-100'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={limitLabelWithTooltip}>
              <OrganizationsPerQueryInput
                value={organizationsPerQueryLimit}
                onChange={onChangeOrganizationsPerQueryInput}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={skipLabelWithTooltip}>
              <Space align='center' className='first-space-item-full'>
                <Select
                  disabled={!isPaidUser}
                  allowClear
                  value={skip_places || 0}
                  onChange={(v) => updateTask({ skip_places: v })}
                >
                  <Option key='0' value={0}>0</Option>
                  <Option key='20' value={20}>20</Option>
                  <Option key='40' value={40}>40</Option>
                  <Option key='60' value={60}>60</Option>
                  <Option key='80' value={80}>80</Option>
                </Select>
                <InfoTooltip title={t('description.skip')}/>
              </Space>
            </Form.Item>
          </Col>
        </Space>
        }
      </Space>

      <Space className='w-100' direction='vertical'>
        {!enrich &&
            <Space className='w-100' size={16}>
              <Form.Item>
                <Space align='center'>
                  <Checkbox
                    checked={dropDuplicates}
                    onChange={onChangeDropDuplicates}
                  ><Trans i18nKey='action.dropDuplicates' /></Checkbox>
                  <InfoTooltip paddingLeft={2} title={<Trans i18nKey='description.dropDuplicates'>
              Drop the same organizations from different queries by `google_id` column.
                    <br/><br/>
              If the amount of ignored rows are less than 5,000% of what was actually extracted, you won&apos;t be billed for ignored records.
                  </Trans>}/>
                </Space>
              </Form.Item>

              {!isCustomLocations &&
                <Form.Item>
                  <Space align='center'>
                    <Checkbox
                      checked={useZipCodes && supportsZipCodes}
                      disabled={!supportsZipCodes}
                      onChange={handleUseZipCodesChange}
                    ><Trans i18nKey='title.useZipCodes' /></Checkbox>
                    <InfoTooltip paddingLeft={2} title={<Trans i18nKey='description.zipCodes'>
              As Google shows only up to 500 places per one query search, you might want to use zip codes for densely populated areas/categories. Use this option to make the scraper search by `restaurant, 10001, NY, US...` instead of `restaurants, New York, US`.
                      <br/><br/>
              This will increase the coverage and execution time. Please visit <a href='https://outscraper.com/google-maps-scrape-all-places' target='_blank' rel='noopener noreferrer'>this page</a> to learn more.
                    </Trans>}/>
                  </Space>
                </Form.Item>}
            </Space>}
      </Space>
    </AdvancedParametersPanel>
  );
};

AdvancedParameters.propTypes = {
  task: PropTypes.object.isRequired,
  updateTask: PropTypes.func.isRequired,
  isPaidUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};
