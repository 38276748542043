import React from 'react';
import { Col, Form, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';
import CountrySelect from './Common/CountrySelect';

export default function EbayProducts() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='product'
      title='eBay Products Scraper'
      subTitle='Returns product listings'
      serviceName='ebay_products'
      learnMoreUrl='https://outscraper.com/ebay-scraper/'
      queriesPlaceholder='https://www.ebay.com/sch/i.html?_from=R40&_nkw=iphone&_sacat=0&_stpos=11704&_fcid=1'
      defaultParams={{
        limit_per_query: 100,
        ip_region: 'US',
      }}
      ExtraItems={({ updateTask, task }) => {
        const { postal_code, country } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.country')}>
              <CountrySelect
                value={country}
                onChange={(country) => updateTask({ country })}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.deliveryPostcode')}>
              <Input
                placeholder='11704'
                value={postal_code}
                onChange={({ target }) => updateTask({ postal_code: target.value })}
              />
            </Form.Item>
          </Col>
        </Space>;
      }}
    />
  );
}
