import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Steps, Form, Select, Button, message, Space, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';

import GA from '../analytics/ga';
import titles from '../shared/data/titles.json';
import industries from '../shared/data/industries.json';
import apiProfile from '../api/apiProfile';
import { useMedia } from '../hooks/useMedia';

const { Option } = Select;

export default function KYC({ auth }) {
  const { profile } = auth;
  const { emailVerified, email } = profile;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  const nextButtonRef = useRef(null);

  if (!localStorage.getItem('kyc')) {
    goToRedirect();
  }

  const preparingYourAccountLocalLabel = t('title.preparingYourAccount');
  const { job_title, industry } = data;
  const STEPS = emailVerified ? 2 : 3;

  const stepsItems = [
    {
      title: t('title.industry'),
    },
    {
      title: t('title.jobRole'),
    },
    {
      title: emailVerified ? t('title.сompletion') : t('title.checkEmail'),
    },
  ];

  useEffect(() => {
    if (nextButtonRef.current) {
      nextButtonRef.current.focus();
    }
  }, [step]);

  async function onNext() {
    GA.track('button', 'KYC next step click', `KYC step ${step + 1}`);

    if (step + 1 >= STEPS) {
      setLoading(true);
      message.loading({ content: preparingYourAccountLocalLabel, key: 'main' });

      if (localStorage.getItem('kyc')) {
        localStorage.removeItem('kyc');

        try {
          const customIndustries = [];
          const enteredIndustries = data['industry'];

          for (let i = enteredIndustries.length - 1; i >= 0; i--) {
            const industry = enteredIndustries[i];
            if (!industries.includes(industry)) {
              customIndustries.push(industry);
              enteredIndustries.splice(i, 1);
            }
          }

          data['industry'] = enteredIndustries.join(';');
          if (customIndustries.length) data['note'] = customIndustries.join('\n');

          await apiProfile.updateKYCFinished(data);
        } catch (error) {
          console.error(error);
        }
      }

      setLoading(false);
      message.success({ content: t('title.ready'), key: 'main' });

      setData({});
      goToRedirect();
    }

    setStep(step + 1);
  }

  function goToRedirect() {
    const redirect = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    window.location = redirect || '/';
  }

  function onPrevious() {
    setStep(step - 1);
  }

  return <Space direction='vertical' size={isDesktop ? 60 : 16} className='space-items-full p-16'>
    <Steps progressDot current={step} items={stepsItems}/>
    <Space direction='vertical' align='center' className='w-100'>
      <Form name='kyc' layout='vertical'>
        <Space direction='vertical' size={16} className='w-100'>
          <Row justify='center' align='middle'>
            <Col xs={24} sm={16} md={12} lg={8} xl={6}>
              {step === 0 &&
                <Form.Item colon={false} label={t('title.whatIndustry')}>
                  <Select
                    mode='tags'
                    allowClear
                    autoFocus
                    showSearch
                    maxTagCount={3}
                    showArrow={false}
                    value={industry}
                    placeholder={t('title.searchIndustries')}
                    optionFilterProp='children'
                    onChange={(v) => setData({ ...data, industry: v })}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && industry) {
                        e.preventDefault();
                        onNext();
                      }
                    }}
                  >
                    {industries.map(t => <Option key={t} value={t}>{t}</Option>)}
                  </Select>
                </Form.Item>
              }

              {step === 1 &&
                <Form.Item colon={false} label={t('title.whatJobRole')}>
                  <Select
                    allowClear
                    autoFocus
                    showSearch
                    notFoundContent={job_title ? t('title.jobRoleNotFound') : t('title.typeToSearch')}
                    showArrow={false}
                    value={job_title}
                    placeholder={t('title.searchJobRoles')}
                    optionFilterProp='children'
                    onChange={(v) => setData({ ...data, job_title: v })}
                    onSearch={(v) => setData({ ...data, job_title: v })}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && job_title) {
                        e.preventDefault();
                        onNext();
                      }
                    }}
                  >
                    {job_title && titles.map(t => <Option key={t} value={t}>{t}</Option>)}
                  </Select>
                </Form.Item>
              }

              {(step === 2 && !emailVerified) && <Space direction='vertical' align='center' size={8} className='w-100'>
                <h2 className='h2'><Trans i18nKey='title.checkInbox'/></h2>
                <Space direction='vertical' align='center' className='w-100'>
                  <span className='nobreak'><Trans i18nKey='description.checkEmail'/> <strong>{email}</strong><Trans
                    i18nKey='dot'>.</Trans></span>
                  <Trans i18nKey='description.checkEmailClickLink'/>
                </Space>
              </Space>}
            </Col>
          </Row>
          <Row gutter={[8, 8]} justify='center' align='middle'>
            <Col xs={24} sm={8} md={6} lg={4} xl={3}>
              <Button
                className='w-100'
                onClick={onPrevious}
                disabled={step <= 0 || loading}
              >
                <Trans i18nKey='title.back'/>
              </Button>
            </Col>
            <Col xs={24} sm={8} md={6} lg={4} xl={3}>
              <Button
                className='w-100'
                ref={nextButtonRef}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onNext();
                  }
                }}
                type='primary'
                onClick={onNext}
                loading={loading}
                disabled={(step === 0 && isEmpty(industry)) || (step === 1 && isEmpty(job_title)) || loading}
              >
                <Trans i18nKey='title.next' />
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </Space>
  </Space>;
}

KYC.propTypes = {
  auth: PropTypes.object.isRequired,
};
