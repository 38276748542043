import React from 'react';
import { Col, Form, Select, Checkbox, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import SimpleService from './Common/SimpleService';

const { Option } = Select;

export default function YouTubeCommentsScraper() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='page'
      title='YouTube Comments Scraper'
      subTitle='Returns comments'
      queriesLabel='Video links or video IDs'
      apiTag='YouTube/paths/~1youtube-comments/get'
      serviceName='youtube_comments_service'
      queriesPlaceholder='ph5pHgklaZ0&#10;https://www.youtube.com/watch?v=ph5pHgklaZ0'
      learnMoreUrl='https://outscraper.com/youtube-comments-scraper'
      ExtraItems={({ updateTask, task }) => {
        const { sort = '', replies } = task;

        return <Space className='w-100' size={16} direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.sorting', 'Sorting')}>
              <Select value={sort} onChange={(v) => updateTask({ sort: v })}>
                <Option key='top' value=''><Trans i18nKey='title.top'>Top</Trans></Option>
                <Option key='newest' value='newest'><Trans i18nKey='title.newest'>Newest</Trans></Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item>
              <Checkbox checked={replies} onChange={() => updateTask({ replies: !replies })}>Extract replies</Checkbox>
            </Form.Item>
          </Col>
        </Space>;
      }}
    />
  );
}
