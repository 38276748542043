import React from 'react';
import { Form, DatePicker, InputNumber, Select, Space, Col, Button } from 'antd';
import dayjs from 'dayjs';

import BaseService from './Common/BaseService';
import plainQueriesSwitchFormHeader from './Common/Google/plainQueriesSwitchFormHeader';
import StartStopLocationsSelect from './Common/Google/TrafficStartStopLocationsSelect';
import CustomQueries from './Common/Selects/CustomQueries';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import QueriesFormHeader from './Common/Google/QueriesFormHeader';
import { ErrorMessage } from '../../shared/Components/ErrorMessage.jsx';
import { validateIntervalDateFrameExpanded } from '../../utils/validation';
import { PlusCircleOutlined } from '@ant-design/icons';

const CUSTOM_QUERIES_KEY = 'traffic/customQueries';
const { Option } = Select;

export default function GoogleMapsExtractor() {
  const taskExtraDefaultParams = {
    queries: [['', '']],
    plainQueries: '',
    dateFrame: [dayjs().startOf('hour'), dayjs().endOf('hour')],

    interval: 60,

    UISettings: {
      isCustomQueries: localStorage.getItem(CUSTOM_QUERIES_KEY) === '1',
    },
  };

  function formatTask(task) {
    const { queries, plainQueries, dateFrame, interval, UISettings = {} } = task;
    const { isCustomQueries } = UISettings;

    return { ...task,
      queries: isCustomQueries ? formatPlainQueries(plainQueries) : queries,
      initial_time: dateFrame[0].unix(),
      finish_time: dateFrame[1].unix(),
      interval,
    };
  }

  function formatPlainQueries(text) {
    const results = [];
    text.split(/\r?\n/).forEach(row => {
      const items = row.split(/ {4}|\t/);

      if (items.length === 2) {
        results.push([items[0], items[1]]);
      }
    });
    return results;
  }

  function deformatTask(task) {
    const { initial_time, finish_time, plainQueries = '' } = task;
    return { ...task, plainQueries, dateFrame: [dayjs(initial_time*1000), dayjs(finish_time*1000)] };
  }

  return (
    <BaseService
      title='Google Maps Traffic Scraper'
      subTitle='Returns directions between two points'
      serviceName='google_maps_traffic_service'
      unitName='request'
      learnMoreUrl='https://outscraper.com/google-maps-traffic-extractor'
      tutorialLink='https://outscraper.com/scrape-google-maps-traffic'
      apiTag='Google/paths/~1maps~1directions/get'
      taskUpdateAfterSubmit={{ queries: [['', '']], plainQueries: '', tags: [] }}
      taskExtraDefaultParams={taskExtraDefaultParams}
      validateTask={validateIntervalDateFrameExpanded}
      formatTask={formatTask}
      deformatTask={deformatTask}
      FormBody={({ task, updateTask, invalidFields }) => {
        const { queries, plainQueries, inputFile, dateFrame, interval, travel_mode = '', UISettings = {}, settings } = task;
        const { isCustomQueries } = UISettings;
        const isTransit = travel_mode === 'transit';

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        function disabledDate(current) {
          if (isTransit) {
            return current && current < dayjs().add(-7, 'day');
          } else {
            return false;
          }
        }

        const queriesLabel = (
          <QueriesFormHeader
            title={<span>Queries, Coordinates or Places splitted by tab or 4 spaces</span>}
            tooltipTitle={<span>You can use anything that will work on Google Maps site</span>}
          >
            {plainQueriesSwitchFormHeader(task, updateTask, CUSTOM_QUERIES_KEY)}
          </QueriesFormHeader>
        );

        const timeIntervalLabelWithTooltip =
          <Space align='center'>
            Time interval (min):
            <InfoTooltip title={<>
                  Parameter specifies the interval to use between departure time and finish time.
            </>}/>
          </Space>;
        function onAdd() {
          const newLocations = [...queries, ['', '']];
          updateTask({ queries: newLocations });
        }

        return <Space size={16} className='w-100' direction='vertical'>
          {isCustomQueries ? (
            <Col xs={24} xl={16} lg={12}>
              <Form.Item required label={queriesLabel} className='queries-input'>
                <CustomQueries
                  value={plainQueries}
                  onChange={(v) => updateTask({ plainQueries: v })}
                  inputFile={inputFile}
                  onFileUploaded={onFileUploaded}
                  placeholder='17.8805168994, -76.9060696994	17.8810785996, -76.9055896991&#10;Intersection of 19th Ave and Santiago St, San Francisco    Intersection of 19th Ave and Irving St, San Francisco'
                  error={invalidFields.includes('queries')}
                />
              </Form.Item>
            </Col>
          ) : (
            <Col xs={24}>
              <Space direction='vertical' className='w-100'>
                <Col xs={24} lg={8} xl={6}>
                  <Space align='center' className='justifySpaceBetween'>
                    <h4 className='h4'>Locations:</h4>
                    {plainQueriesSwitchFormHeader(task, updateTask, CUSTOM_QUERIES_KEY)}
                  </Space>
                </Col>
                <Button type='link' icon={<PlusCircleOutlined/>} onClick={onAdd}>
                    Add
                </Button>
                <StartStopLocationsSelect locations={queries} onChange={(v) => updateTask({ queries: v })} invalidFields={invalidFields} />
              </Space>
            </Col>
          )}

          <Col xs={24} lg={8} xl={6}>
            <Form.Item label='Time frame:' required>
              <DatePicker.RangePicker
                className='w-100'
                separator='-'
                value={dateFrame}
                ranges={{
                  Hour: [dayjs().startOf('hour'), dayjs().endOf('hour')],
                  Day: [dayjs().startOf('day'), dayjs().endOf('day')],
                  Week: [dayjs().startOf('week'), dayjs().endOf('week')],
                  Month: [dayjs().startOf('month'), dayjs().endOf('month')],
                }}
                showTime
                format='YYYY/MM/DD HH:mm:ss'
                onChange={(v) => updateTask({ dateFrame: v })}
                disabledDate={disabledDate}
                status={invalidFields.includes('dateFrame') ? 'error' : ''}
              />
              {invalidFields.includes('dateFrame') && (
                <ErrorMessage />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={timeIntervalLabelWithTooltip} required>
              <InputNumber className='w-100' required value={interval} onChange={(v) => updateTask({ interval: v })} min={1} status={invalidFields.includes('interval') ? 'error' : ''} />
              {invalidFields.includes('interval') && (
                <ErrorMessage />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Form.Item label='Travel Mode'>
              <Select value={travel_mode} onChange={(v) => updateTask({
                travel_mode: v,
                dateFrame: [dayjs().startOf('hour'), dayjs().endOf('hour')]
              })}>
                <Option key='' value=''>Best</Option>
                <Option key='car' value='car'>Driving</Option>
                <Option key='transit' value='transit'>Transit</Option>
                <Option key='walk' value='walk'>Walking</Option>
                <Option key='bike' value='bike'>Cycling</Option>
                <Option key='flight' value='flight'>Flights</Option>
              </Select>
            </Form.Item>
          </Col>
        </Space>;
      }}
    />
  );
}
