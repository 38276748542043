import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Select, Checkbox, Space, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { toTitleCase } from '../../../utils/utils';
import LocationsSelect from './Selects/LocationsSelect';
import CustomItemsSelect from './Selects/CustomItemsSelect';
import CustomQueries from './Selects/CustomQueries';
import plainQueriesSwitchFormHeader from './Google/plainQueriesSwitchFormHeader';
import QueriesFormHeader from './Google/QueriesFormHeader';
import { ErrorMessage } from '../../../shared/Components/ErrorMessage.jsx';

const { Option } = Select;

const yelpCategories = [
  'restaurants',
  'shopping',
  'nightlife',
  'bars',
  'beauty & spas',
  'health & medical',
  'home services',
  'automotive',
  'local services',
  'event planning & services',
  'hotels & travel',
  'pets',
  'arts & entertainment',
  'fitness & instruction',
  'real estate',
  'active life',
  'education',
  'financial services',
  'public services & government',
  'professional services'
];

export default function YelpQueriesFormItems({
  task, onUpdate, isCustomQueries, isCustomLocations,
  onIsCustomLocationsChange, customQueriesKey, enrichmentFields, singleRegion, invalidFields
}) {
  const { queries, categories, customLocations, input_file, tags = [], settings } = task;

  const { t } = useTranslation();

  function onQueriesChange(queries) {
    onUpdate({ queries });
  }

  function getAutoTag(categories) {
    if (isEmpty(categories)) return null;

    let tag = `${categories[0]}`;
    if (categories.length > 1) tag += ` +${categories.length - 1}`;

    return tag;
  }

  function onCategoriesChange(newCategories) {
    const payload = { categories: newCategories };

    if (isEmpty(tags) || tags[0] === getAutoTag(categories)) {
      const autoTag = getAutoTag(newCategories);

      if (autoTag) {
        if (isEmpty(tags)) {
          payload.tags = [autoTag];
        } else {
          const newTags = [...tags];
          newTags[0] = autoTag;
          payload.tags = newTags;
        }
      } else {
        if (tags.length === 1) {
          payload.tags = [];
        } else {
          const newTags = [...tags];
          newTags.slice(1);
          payload.tags = newTags;
        }
      }
    }

    onUpdate(payload);
  }

  function onCustomLocationsChange(customLocations) {
    onUpdate({ customLocations });
  }

  function onFileUploaded(input_file, enrich, output_extension) {
    onUpdate({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
  }

  function handleOnIsCustomLocationsChange() {
    const value = !isCustomLocations;
    onIsCustomLocationsChange(value);
  }

  const queriesLabel = (
    <QueriesFormHeader
      title={
        <span>Queries (URLs or categories + locations separated by vertical bar ` | `)</span>
      }
    >
      {plainQueriesSwitchFormHeader(task, onUpdate, customQueriesKey, true)}
    </QueriesFormHeader>
  );

  const categoriesSelectLabel = (
    <QueriesFormHeader
      title={
        <Trans i18nKey='title.categories' />
      }
    >
      {plainQueriesSwitchFormHeader(task, onUpdate, customQueriesKey, true)}
    </QueriesFormHeader>
  );

  return <Space size={16} className='w-100' direction='vertical'>
    {isCustomQueries ? (
      <Col xs={24} lg={16} xl={12}>
        <Form.Item
          required
          className='queries-input'
          label={queriesLabel}
        >
          <CustomQueries
            value={queries}
            onChange={onQueriesChange}
            inputFile={input_file}
            onFileUploaded={onFileUploaded}
            placeholder='https://www.yelp.com/search?cflt=restaurants&find_loc=San+Francisco%2C+CA&#10;https://www.yelp.com/search?cflt=Plumbing&find_loc=San+Francisco%2C+CA&#10;Auto Repair | San Mateo, CA'
            enrichmentFields={enrichmentFields}
            error={invalidFields.includes('queries')}
          />
        </Form.Item>
      </Col>
    ) : (
      <Space size={16} className='w-100' direction='vertical'>
        <Col xs={24} lg={16} xl={12}>
          <Form.Item
            required
            className='categories-select'
            label={categoriesSelectLabel}
          >
            <Select
              allowClear
              autoFocus
              showSearch
              autoClearSearchValue={false}
              size='large'
              mode={'tags'}
              maxTagCount={4}
              tokenSeparators={[',', '\n']}
              placeholder={t('action.selectCategories')}
              value={categories}
              onChange={onCategoriesChange}
              status={invalidFields.includes('categories') ? 'error' : ''}
            >{yelpCategories.map(c => <Option key={c} value={c}>{toTitleCase(c)}</Option>)}</Select>
            {invalidFields.includes('categories') && (
              <ErrorMessage />
            )}
          </Form.Item>
        </Col>

        <Col xs={24} lg={20} xl={15}>
          <Form.Item
            required
            className='locations-select'
            label={<Trans i18nKey='title.locations' />}
          >
            <Space className='w-100' direction='vertical' size={4}>
              {isCustomLocations ? <>
                <CustomItemsSelect
                  placeholder='New York, NY&#10;90001, Los Angeles, LA'
                  value={customLocations}
                  onChange={onCustomLocationsChange}
                  error={invalidFields.includes('customLocations')} />
              </> : <LocationsSelect task={task} onUpdate={onUpdate} singleRegion={singleRegion} error={invalidFields.includes('locations')} />}

              <Checkbox
                checked={isCustomLocations}
                onChange={handleOnIsCustomLocationsChange}
              ><Trans i18nKey='title.customLocations' /></Checkbox>
            </Space>
          </Form.Item>
        </Col>
      </Space>
    )}
  </Space>;
}

YelpQueriesFormItems.propTypes = {
  task: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isCustomQueries: PropTypes.bool.isRequired,
  isCustomCategories: PropTypes.bool.isRequired,
  isCustomLocations: PropTypes.bool.isRequired,
  onIsCustomCategoriesChange: PropTypes.func.isRequired,
  onIsCustomLocationsChange: PropTypes.func.isRequired,
  extraHint: PropTypes.string,
  customQueriesKey: PropTypes.string,
  enrichmentFields: PropTypes.array,
  singleRegion: PropTypes.string,
  invalidFields: PropTypes.array.isRequired
};
