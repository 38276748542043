import React from 'react';
import { Col, Form, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from './Common/SimpleService';

const { Option } = Select;

export default function AirbnbSearch() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='record'
      title='Airbnb Search Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='airbnb_search'
      learnMoreUrl='https://outscraper.com/airbnb-scraper/'
      queriesPlaceholder='https://www.airbnb.com/s/Italy/homes?tab_id=...&#10;https://www.airbnb.com/s/Paris/homes?tab_id=...'
      apiTag='Others/paths/~1airbnb~1search/get'
      defaultParams={{ currency: 'USD', language: 'en' }}
      ExtraItems={({ updateTask, task }) => {
        const { currency, language } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.currency')}>
              <Select value={currency} onChange={(currency) => updateTask({ currency })}>
                <Option value='USD'>USD</Option>
                <Option value='AUD'>AUD</Option>
                <Option value='BRL'>BRL</Option>
                <Option value='BGN'>BGN</Option>
                <Option value='CAD'>CAD</Option>
                <Option value='CLP'>CLP</Option>
                <Option value='CNY'>CNY</Option>
                <Option value='COP'>COP</Option>
                <Option value='CRC'>CRC</Option>
                <Option value='HRK'>HRK</Option>
                <Option value='CZK'>CZK</Option>
                <Option value='DKK'>DKK</Option>
                <Option value='EGP'>EGP</Option>
                <Option value='AED'>AED</Option>
                <Option value='EUR'>EUR</Option>
                <Option value='GHS'>GHS</Option>
                <Option value='HKD'>HKD</Option>
                <Option value='HUF'>HUF</Option>
                <Option value='INR'>INR</Option>
                <Option value='IDR'>IDR</Option>
                <Option value='ILS'>ILS</Option>
                <Option value='JPY'>JPY</Option>
                <Option value='KES'>KES</Option>
                <Option value='MYR'>MYR</Option>
                <Option value='MXN'>MXN</Option>
                <Option value='MAD'>MAD</Option>
                <Option value='TWD'>TWD</Option>
                <Option value='NZD'>NZD</Option>
                <Option value='NOK'>NOK</Option>
                <Option value='PEN'>PEN</Option>
                <Option value='PHP'>PHP</Option>
                <Option value='PLN'>PLN</Option>
                <Option value='GBP'>GBP</Option>
                <Option value='QAR'>QAR</Option>
                <Option value='RON'>RON</Option>
                <Option value='SAR'>SAR</Option>
                <Option value='SGD'>SGD</Option>
                <Option value='ZAR'>ZAR</Option>
                <Option value='KRW'>KRW</Option>
                <Option value='SEK'>SEK</Option>
                <Option value='CHF'>CHF</Option>
                <Option value='THB'>THB</Option>
                <Option value='TRY'>TRY</Option>
                <Option value='UGX'>UGX</Option>
                <Option value='UAH'>UAH</Option>
                <Option value='UYU'>UYU</Option>
                <Option value='VND'>VND</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6} xl={4}>
            <Form.Item label={t('title.language')}>
              <Select
                showSearch
                value={language}
                onChange={(language) => updateTask({ language })}
              >
                <Option key='en' value='en'>EN</Option>
                <Option key='de' value='de'>DE</Option>
                <Option key='fr' value='fr'>FR</Option>
                <Option key='es' value='es'>ES</Option>
                <Option key='it' value='it'>IT</Option>
              </Select>
            </Form.Item>
          </Col>
        </Space>;
      }}
    />
  );
}
