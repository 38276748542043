import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, Popconfirm, message, Space } from 'antd';
import { UserAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import { isPaidUser } from '../../utils/utils';
import { removeTenant, inviteTenant } from '../../redux/actions/profileActions';
import { warningMessage } from '../../shared/Components/PaymentInfo';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';
import { useMedia } from '../../hooks/useMedia';

export default function Tenants({ tenants = [], accountNotValid }) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.profileReducer.loading);
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const paidUser = isPaidUser();
  const { isDesktop } = useMedia();

  function onInvite({ email }) {
    if (accountNotValid) {
      message.warning(warningMessage, 10);
    } else {
      dispatch(inviteTenant(email));
      setModalVisible(false);
    }
  }

  function onRemoveTenant(tenantUserId) {
    dispatch(removeTenant(tenantUserId));
  }

  return <>
    {tenants.map((tenant, index) => (
      <div key={`${tenant.user_id}-${index}`} style={{ padding: 5 }}>
        {tenant.email}
        <Popconfirm
          title={t('title.areYouSure', 'Are you sure?')}
          okText={t('action.yes', 'Yes')}
          cancelText={t('action.no', 'No')}
          onConfirm={() => onRemoveTenant(tenant.user_id)}
          disabled={loading}
        >
          <Button
            size='small'
            type='link'
            title={t('description.removeTeamMember')}
            style={{ paddingLeft: 10 }}
            icon={<DeleteOutlined />}
            disabled={loading}
          />
        </Popconfirm>
      </div>
    ))}
    {tenants.length < 10 &&
      <PremiumFeatureTooltip isPaidUser={paidUser}>
        <Button
          type='link'
          icon={<UserAddOutlined />}
          title={t('description.inviteTeamMember')}
          onClick={() => setModalVisible(true)}
          disabled={loading || !paidUser}
        >{t('action.invite')}</Button>
      </PremiumFeatureTooltip>
    }

    <Modal
      width={isDesktop ? '460px' : '100%'}
      destroyOnClose
      footer={null}
      title={t('description.inviteTeamMember')}
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
    >
      <Trans i18nKey='description.inviteTeamMemberLong' />.<br/><br/>
      <Form name='add-tenant' layout='vertical' onFinish={onInvite}>
        <Space direction='vertical' className='w-100' size={16}>
          <Form.Item name='email' label={t('title.email', 'Email')} rules={[{ type: 'email', required: true }]}><Input /></Form.Item>
          <Form.Item><Button className='w-100' type='primary' htmlType='submit'>{t('action.sendInvitation')}</Button></Form.Item>
        </Space>
      </Form>
    </Modal>
  </>;
}

Tenants.propTypes = {
  tenants: PropTypes.array.isRequired,
  accountNotValid: PropTypes.bool,
};
