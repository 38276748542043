import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import GA from '../analytics/ga';
import apiProfile from '../api/apiProfile';
import LoginSignupForm from './Common/LoginSignupForm';
import EmailFormItem from './Common/EmailFormItem';
import PasswordFormItem from './Common/PasswordFormItem';
import { addReferrerAndSource } from './Common/utils';

export default function Signup({ auth }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [checkEmail, setCheckEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (window.top !== window.self) {
    window.top.location = window.location;
  }

  async function onFinish(payload) {
    setLoading(true);
    addReferrerAndSource(payload);

    GA.track('button', 'Signup', 'Intent');

    try {
      const { data } = await apiProfile.createProfile(payload);
      const { access_token } = data;

      notification.success({ message: t('title.checkInbox') });
      GA.track('button', 'Signup success', payload.source || 'No source');

      setCheckEmail(payload['email']);
      auth.setSession(access_token, true);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  async function onGoogleLogin(token) {
    const payload = { google_auth_token: token };
    setLoading(true);
    addReferrerAndSource(payload);

    try {
      const { data } = await apiProfile.getAuthToken(payload);
      const { confirm_email, access_token } = data;

      if (access_token) {
        auth.setSession(access_token, true);
      } else if (confirm_email) {
        notification.success({ message: t('title.checkInbox') });
        setCheckEmail(payload['email']);
      }

    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  function toLogin() {
    navigate('/login');
  }

  return <LoginSignupForm
    captcha
    title={t('title.welcome')}
    subTitle={t('description.signUp')}
    loading={loading}
    location={location}
    onFinish={onFinish}
    onGoogleLogin={onGoogleLogin}
    checkEmail={checkEmail}
    AfterButtonComponent={() => <><Trans i18nKey='description.alreadyHaveAccount' /><Button type='link' style={{ marginLeft: -15 }} onClick={toLogin}><Trans i18nKey='title.logIn' /></Button></>}
    submitButtonTitle={t('title.signUp')}
  >
    <EmailFormItem />
    <PasswordFormItem />
  </LoginSignupForm>;
}
Signup.propTypes = {
  auth: PropTypes.object.isRequired
};
