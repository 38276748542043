import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Typography, Space } from 'antd';
import { Trans } from 'react-i18next';
import { VscSparkleFilled } from 'react-icons/vsc';

const { Title } = Typography;

export default function HelperAIModal({ isModalOpen, handleCancel, form, handleSubmit, loading }) {
  return (
    <Modal
      title={
        <Space size='small' align='center' className='justifyCenter'>
          <VscSparkleFilled className='h3' style={{ color : '6A5ACD' }} />
          <Title level={4}>Generate Categories with AI</Title>
        </Space>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      centered
      width={675}
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <Form.Item
          label='Enter domain of company you want to generate leads for and add your offer description'
          name='user_input'
          className='mb-16'
          rules={[{ required: true, message: 'Please enter offer description' },
            { max: 250, message: 'Your request has exceeded the 250-character limit', count: 250 }]}>
          <Input.TextArea placeholder='company.com - we`re selling coffee machines' rows={5} allowClear disabled={loading}/>
        </Form.Item>
        <Button type='primary' htmlType='submit' loading={loading} block>
          <Trans i18nKey='action.send' />
        </Button>
      </Form>
    </Modal>
  );
}

HelperAIModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
