import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { isPaidUser } from '../../utils/utils';
import PremiumFeatureTooltip from '../../shared/Components/PremiumFeatureTooltip';

export default function NotificationsSettings({ loading, value, onChange }) {
  const { on_task_finish } = value;
  const { t } = useTranslation();
  const paidUser = isPaidUser();

  return <PremiumFeatureTooltip isPaidUser={paidUser}>
    <Checkbox
      disabled={loading || !paidUser}
      checked={on_task_finish}
      onChange={() => onChange({ ...value, on_task_finish: !on_task_finish })}
    >{t('action.notify')}</Checkbox>
  </PremiumFeatureTooltip>;
}

NotificationsSettings.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
