import React from 'react';
import { Col, Form, InputNumber, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import SimpleService from './Common/SimpleService';
import InfoTooltip from '../../shared/Components/InfoTooltip';
import DomainSelectItem from './Common/Amazon/DomainSelectItem';

const { Option } = Select;

export default function AmazonReviewsExtractor() {
  const { t } = useTranslation();

  return (
    <SimpleService
      title='Amazon Reviews Scraper'
      subTitle='Returns reviews from a list of products'
      serviceName='amazon_reviews_service_v2'
      startTaskButtonTitle='Export Reviews'
      unitName='review'
      learnMoreUrl='https://outscraper.com/amazon-reviews-scraper/'
      apiTag='Amazon/paths/~1amazon~1reviews/get'
      queriesLabel='Amazon ASINs or product URLs'
      queriesPlaceholder='B07GBRG2FN&#10;https://www.amazon.com/gp/product/B07XJV82W3'
      limitType={null}
      defaultParams={{
        sort: 'helpful',
        domain: 'amazon.com',
        limit_per_query: 100,
        filterByReviewer: 'all_reviews',
        filterByStar: 'all_stars',
      }}
      recentCutoffSorting={'recent'}
      sortingOptions={[
        { value: 'helpful', text: 'Top reviews' },
        { value: 'recent', text: 'Most recent' },
      ]}
      ExtraItems={({ updateTask, task }) => {
        const { limit_per_query, filterByReviewer, filterByStar, domain } = task;

        const reviewsLimitPerProductLabelWithTooltip = () =>
          <Space align='center'>
            {t('title.reviewsLimitPerProduct')}
            <InfoTooltip title={
              <>Parameter specifies the limit of reviews to scrape from one URL/ASIN above.<br/><br/>We extract only public information, and a limit of 100 records is set from the website. To get more  reviews, use filters.</>
            }/>
          </Space>;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item label={t('title.filtering')}>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <Form.Item className='w-100'>
                    <Select
                      showSearch
                      value={filterByReviewer}
                      optionFilterProp='children'
                      onChange={(filterByReviewer) => updateTask({ filterByReviewer })}
                    >
                      <Option key={'all_reviews'} value={'all_reviews'}>All reviewers</Option>
                      <Option key={'avp_only_reviews'} value={'avp_only_reviews'}>Verified purchase only</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item className='w-100'>
                    <Select
                      showSearch
                      value={filterByStar}
                      optionFilterProp='children'
                      onChange={(filterByStar) => updateTask({ filterByStar })}
                    >
                      <Option key={'all_stars'} value={'all_stars'}>All stars</Option>
                      <Option key={'five_star'} value={'five_star'}>5 star only</Option>
                      <Option key={'four_star'} value={'four_star'}>4 star only</Option>
                      <Option key={'three_star'} value={'three_star'}>3 star only</Option>
                      <Option key={'two_star'} value={'two_star'}>2 star only</Option>
                      <Option key={'one_star'} value={'one_star'}>1 star only</Option>
                      <Option key={'positive'} value={'positive'}>All positive</Option>
                      <Option key={'critical'} value={'critical'}>All critical</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={reviewsLimitPerProductLabelWithTooltip()}
              wrapperCol={{ xs: { span: 24 }, lg: { span: 6 }, xl: { span:4 } }}
            >
              <Space align='center' className='first-space-item-full'>
                <InputNumber
                  className='w-100'
                  value={limit_per_query} onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
                  min={1}
                  max={100}
                  step={10}
                />
              </Space>
            </Form.Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <DomainSelectItem
              value={domain}
              onChange={(domain) => updateTask({ domain })}
            />
          </Col>

          {/* <Form.Item label={t('title.language', 'Language')}>
            <Select
              showSearch
              style={{ width: 200 }}
              value={language}
              onChange={(language) => updateTask({ language })}
            >
              <Option key='en' value='en'>EN</Option>
              <Option key='de' value='de'>DE</Option>
              <Option key='fr' value='fr'>FR</Option>
            </Select>
          </Form.Item><br/> */}
        </Space>;
      }}
    />
  );
}
