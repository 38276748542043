import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Mixpanel from '../../../../analytics/mixpanel.js';
import { VscSparkleFilled } from 'react-icons/vsc';
import { Select, Space, Tooltip, Button, Form, notification } from 'antd';
import config from 'react-global-configuration';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { useMedia } from '../../../../hooks/useMedia';

import { toTitleCase, isPaidUser, getLanguage } from '../../../../utils/utils';
import types from '../../../../shared/data/types.json';
import InfoTooltip from '../../../../shared/Components/InfoTooltip';
import { ErrorMessage } from '../../../../shared/Components/ErrorMessage.jsx';
import HelperAIModal from '../../../../shared/Components/HelperAIModal.js';
import apiAIService from '../../../../api/apiAIHelper.js';

const { Option } = Select;

const StyledButton = styled.button`
  color: #262626;
  text-decoration: underline;
`;

const buttonAIStyles = {
  position: 'absolute',
  right: '20px',
  top: '45%',
  transform: 'translateY(-50%)',
  height: 'auto',
  zIndex: 10,
  padding: 0,
};

function CategoriesSelect({ value = [], onChange, noFreeText, maxExactMatchValues = 25, error }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { isDesktop } = useMedia();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const isGoogleMaps = window.location.href.includes('googleMaps');
  const paidUser = isPaidUser();

  function showModal() {
    setIsModalOpen(true);
  }

  function handleCancel() {
    if (loading) {
      notification.warning({
        message: 'Please wait while the request is being processed',
        description: 'Please do not close the modal until it is finished.',
      });
    } else {
      setIsModalOpen(false);
    }
  }

  async function handleSubmit(values) {
    setLoading(true);
    try {
      Mixpanel.track('AI categories selection', { text: values.user_input });

      const { data } = await apiAIService.getCategories({ ...values, language: getLanguage() });
      const { categories, description } = data;

      if (categories && categories.length > 0) {
        onChange(categories.filter(category => types.includes(category)));
        form.resetFields();

        notification.success({
          message: t('title.selected'),
          description: description,
          duration: 15,
        });
      } else {
        notification.error({ message: t('errors.notFound') });
      }
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setIsModalOpen(false);
      setLoading(false);
    }
  }

  function handleOnChange(selectedTypes) {
    let includesForeign = false;

    if (selectedTypes.length <= maxExactMatchValues && !selectedTypes.every(r => types.includes(r))) {
      includesForeign = true;
    }

    onChange(selectedTypes, includesForeign);
  }

  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      setSearchValue('');
    }
  };

  return <Space size={4} direction='vertical' className='w-100'>
    <Space direction='vertical' size={[8, 0]} className='w-100'>
      <Space align='center' className='nobreak first-space-item-full'>
        <Tooltip
          placement='right'
          mouseEnterDelay={0.3}
          title={value.length > 4 ? value.join(', ') : null}
        >
          <Space className='w-100 positionRelative' direction='vertical'>
            <Select
              allowClear
              autoFocus
              showSearch
              autoClearSearchValue={false}
              size='large'
              mode={noFreeText ? 'multiple' : 'tags'}
              maxTagCount={4}
              className='categories-select'
              tokenSeparators={[',', '\n']}
              placeholder={t('action.selectCategories')}
              value={value}
              onChange={handleOnChange}
              searchValue={searchValue}
              onSearch={setSearchValue}
              onDropdownVisibleChange={handleDropdownVisibleChange}
              status={error ? 'error' : ''}
            >{types.map(r => <Option key={r} value={r}>{toTitleCase(r)}</Option>)}
            </Select>
            {isGoogleMaps && paidUser && <Button type='link' icon={<VscSparkleFilled />} onClick={showModal} style={buttonAIStyles} />}
          </Space>
        </Tooltip>
        <InfoTooltip title={
          <>
            <Trans i18nKey='description.selectCategories'>
              Dropdown is based on the following&nbsp;
              <a target='_blank' rel='noopener noreferrer' href={config.get('googleMapsCategoriesUrl')}>
                list of categories
              </a>. You still can use any other categories that will work on Google Maps.<br /><br />
              Sometimes Google adds other categories to your searches,
              please use <a href='https://outscraper.com/google-maps-data-scraper-filters/' target='_blank' rel='noopener noreferrer'>Filters</a> from Advanced Parameters to get only the exact categories.
            </Trans>
            {value && value.length > 0 && <>
              <br />
              <br />
              Selected: {value.join(', ')}
            </>}
          </>
        } />
      </Space>
      {error && (
        <ErrorMessage />
      )}
    </Space>
    <Space direction={isDesktop ? 'horizontal' : 'vertical'}>
      <Space wrap='wrap'>
        <label>
          <Trans i18nKey='title.try' />:
        </label>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(['doctor'])}
        >Doctor,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(['restaurant'])}
        >Restaurant,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange([
            'restaurant', 'bar', 'coffee shop', 'pizza restaurant', 'fast food restaurant', 'ice cream shop', 'bakery',
            'sandwich shop', 'bar & grill', 'cafe', 'juice shop', 'steak house', 'chocolate shop',
            'donut shop', 'dessert shop', 'seafood restaurant', 'donut shop',
          ])}
        >F&B,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(types.slice(0, 250))}
        >Top 250,</StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(types.slice(0, 500))}
        >Top 500,</StyledButton>
      </Space>
      <a href={config.get('googleMapsCategoriesUrl')} target='_blank' rel='noopener noreferrer'>
        <Trans i18nKey='link.allCategories' />
      </a>
    </Space>
    <HelperAIModal
      isModalOpen={isModalOpen}
      handleCancel={handleCancel}
      form={form}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  </Space>;
}

CategoriesSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  noFreeText: PropTypes.bool,
  maxExactMatchValues: PropTypes.number,
  error: PropTypes.bool,
};

export default React.memo(CategoriesSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
});
