import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { message, Alert, Button, Card, Form, Input, Select, Popconfirm, Col, Row, Space } from 'antd';
import { SaveOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from 'lodash';

import GA from '../../analytics/ga';
import { regions } from '../../shared/data/regions';
import { fetchBillingInfo, updateBillingInfo, connectPaymentMethod, makePayment, fetchProfile, removePaymentMethod } from '../../redux/actions/profileActions';
import Balance from './Balance';
import { useMedia } from '../../hooks/useMedia';

const { Option } = Select;

export default function BillingInformation({ location = {}, authForwarded = {} }) {
  const { search } = location;
  const { session_id, checkout, secondary_tab } = queryString.parse(search);

  const { profile: authProfile } = authForwarded || {};
  const { emailVerified, region, roles } = authProfile || {};
  const isSupport = roles.includes('support');

  const profile = useSelector(state => state.profileReducer.profile);
  const billingInfo = useSelector(state => state.profileReducer.billingInfo);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobileOrTable } = useMedia();

  const { balance = 0, account_status, risky = false } = profile;
  const accountNotValid = account_status? account_status !== 'valid' : false;

  const [formUpdated, setFormUpdated] = useState(false);
  const [edited, setEdited] = useState(false);
  const { payment_method = {}, source = {}, address, name, description } = billingInfo;
  const { bank_name, account_number, routing_number } = source;
  const { last4 } = payment_method;

  const hasPaymentMethodOrSource = last4 || bank_name;

  const [form] = Form.useForm();

  if (!formUpdated && !isEmpty(billingInfo)) {
    setFormUpdated(true);
    form.setFieldsValue(billingInfo);
  }

  useEffect(() => {
    document.title = t('title.billingInformation');

    if (checkout === 'success' && session_id) {
      if (secondary_tab && secondary_tab === 'true') {
        message.success(t('title.paymentMethodConnectedSecondTab'), 30);
      } else {
        message.success(t('title.paymentMethodConnected'));
      }

      fetchData();
    } else if (checkout === 'true') {
      onConnectPaymentMethod();
      fetchData();
    } else {
      fetchData();
    }
  }, []);

  function fetchData() {
    dispatch(fetchBillingInfo());
    dispatch(fetchProfile());
  }

  function onConnectPaymentMethod() {
    const fromCheckout = checkout === 'true';
    dispatch(connectPaymentMethod(fromCheckout, region));
    GA.track('button', 'Connect payment intent', fromCheckout ? 'Task confirmation' : 'Billing');
  }

  function onRemovePaymentMethod() {
    dispatch(removePaymentMethod());
  }

  function onMakePayment(amount, region) {
    dispatch(makePayment(amount, region, secondary_tab === 'true'));
  }

  function onUpdateBillingInfo() {
    dispatch(updateBillingInfo(form.getFieldsValue()));
  }

  function onFormChange() {
    setEdited(true);
  }

  const paymentMethodCardTitle = () =>
    <Space
      size={16}
      className='paymentMethodCardTitle'
      direction={isMobileOrTable ? 'vertical' : 'horizontal'}
      align={isMobileOrTable ? 'start' : 'center'}
    >
      <h4 className='h4'>{t('title.paymentMethod')}</h4>
      <Space className='space-items-full'>
        <Button
          type='primary'
          title={t('title.addUpdatePaymentMethod')}
          className='add-payment-method w-100'
          loading={loading}
          disabled={loading || risky || !account_status}
          onClick={onConnectPaymentMethod}
          icon={<LinkOutlined />}
        >
          {hasPaymentMethodOrSource ? t('action.update') : t('title.connect')}
        </Button>
        {(balance >= 50 || isSupport) && <Popconfirm
          title={t('title.areYouSure')}
          okText={t('action.yes')}
          cancelText={t('action.no')}
          onConfirm={onRemovePaymentMethod}
        >
          <Button
            className='w-100'
            type='text'
            size='small'
            title={t('title.removePaymentMethod')}
            loading={loading}
            disabled={loading || !hasPaymentMethodOrSource}
            icon={<DeleteOutlined />}
          >
            {t('action.remove', 'Remove')}
          </Button>
        </Popconfirm>}
      </Space>
    </Space>;

  return (
    <Space size={32} direction='vertical'>
      {accountNotValid && <p className='error-message'>{t('description.addCredits') + (risky ? '' : t('description.orVerifyCard')) + '.'}</p>}
      {risky && <Balance loading={loading} profile={profile} onMakePayment={onMakePayment} emailVerified={emailVerified} />}

      <h3 className='h3'>{t('title.billingInformation')}</h3>
      <Card title={paymentMethodCardTitle()}>
        {hasPaymentMethodOrSource ? (
          <>
            {last4 ? (
              <>**** **** **** {last4}</>
            ) : (
              <Space direction={isMobileOrTable ? 'vertical' : 'horizontal'}>
                {bank_name}
                {routing_number}
                {account_number}
              </Space>
            )}
          </>
        ) : (
          <Space direction='vertical'>
            <span>
              {t('description.noPaymantMethodAssociated')}<Trans i18nKey='dot'>.</Trans>
            </span>
            <Alert message={<>
              <Trans i18nKey='description.paymentsBackedBy'>
                Payments are backed by
                <a href='https://stripe.com' target='_blank' rel='noopener noreferrer'> Stripe</a>. It has the most stringent level of certification available in the payments industry (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='http://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'
                >PCI Service Provider Level 1</a>).
              </Trans>
            </>} type='info' showIcon/>
          </Space>
        )}
      </Card>

      <Card
        title={<h4 className='h4'>{t('title.billingInformation')} ({t('description.billingInformation')})</h4>}
      >
        <Form
          className='noBottomMarginForm'
          form={form}
          name='billing_information'
          initialValues={{ address, name, description }}
          onFieldsChange={onFormChange}
        >
          <Row gutter={[16, 16]} className='mb-16'>
            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.name')} name='name'>
                <Input placeholder={t('description.legalName')} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.country')} name={['address', 'country']}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  placeholder={t('title.country')}
                >
                  {regions.map((r) => (
                    <Option key={r.value} value={r.value}>
                      {r.name} ({r.value})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.address1')} name={['address', 'line1']}>
                <Input placeholder={t('title.address1')} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.address2')} name={['address', 'line2']}>
                <Input placeholder={t('title.address2')} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.city')} name={['address', 'city']}>
                <Input placeholder={t('title.city')} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.postalCode')} name={['address', 'postal_code']}>
                <Input placeholder={t('title.postalCode')} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item title={t('title.state')} name={['address', 'state']}>
                <Input placeholder={t('title.state')} />
              </Form.Item>
            </Col>
          </Row>
          <Space direction={isMobileOrTable ? 'vertical' : 'horizontal'} className='w-100' style={{ justifyContent: 'start' }}>
            <Button
              className='w-100'
              icon={<SaveOutlined />}
              title={t('title.save')}
              type={edited ? 'primary' : 'default'}
              loading={loading}
              onClick={onUpdateBillingInfo}
            >
              {t('title.save')}
            </Button>
          </Space>
        </Form>
      </Card>
      <p className='help-p1'>
        * <Trans i18nKey='description.billingInformationHint' /><Trans i18nKey='dot'>.</Trans>
      </p>
    </Space>
  );
}

BillingInformation.propTypes = {
  location: PropTypes.object.isRequired,
  authForwarded: PropTypes.object.isRequired,
};
