import React from 'react';
import PropTypes from 'prop-types';
import { Form, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import InfoTooltip from '../../../shared/Components/InfoTooltip';
import EnrichmentListSelector from '../Enrichments/EnrichmentListSelector';

function EnrichmentsItem({ task, onChange, updateTask, supportedEnrichmentServices, exludedEnrichmentServices, title,
  learnMoreUrl, serviceOntologies
}) {
  const { t } = useTranslation();
  const localTitle = t(`title.${title}`, title);

  const labelWithTooltip = (
    <Space align='center'>
      <h3 className='h3'>{t('title.enrichmentServices')}</h3>
      <InfoTooltip title={<Trans i18nKey='description.enrichments'>
          There is a limited amount of <a target='_blank' rel='noopener noreferrer' href={`${learnMoreUrl}/#dictionary`}>fields</a> you can get from {{ localTitle }}. However, you can enrich the data with other tools by adding the services you need to the scraping pipeline.
        <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/enrichment-services/'> Learn more</a>
      </Trans>}/>
    </Space>
  );

  return (
    <Form.Item label={labelWithTooltip}>
      <EnrichmentListSelector
        task={task}
        updateTask={updateTask}
        onChange={onChange}
        serviceOntologies={serviceOntologies}
        supportedEnrichmentServices={supportedEnrichmentServices}
        exludedEnrichmentServices={exludedEnrichmentServices}
      />
    </Form.Item>
  );
}

EnrichmentsItem.propTypes = {
  title: PropTypes.string,
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  supportedEnrichmentServices: PropTypes.array.isRequired,
  exludedEnrichmentServices: PropTypes.array,
  serviceOntologies: PropTypes.array,
  learnMoreUrl: PropTypes.string,
};

export default React.memo(EnrichmentsItem, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
