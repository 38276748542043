import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import apiProfile from '../../api/apiProfile';

const { TextArea } = Input;

export default function FeatureRequestModal({ open, onChange, title, bug }) {
  const [text, setText] = useState('');
  const { t } = useTranslation();

  async function onSend() {
    onClose();

    try {
      const { data } = await apiProfile.featureRequest(window.location.href + ' - ' + text, bug);
      notification.success({ message: data });
    } catch (error) {
      notification.error({ message: error.message });
    }
    setText('');
  }

  function onClose() {
    onChange(false);
  }

  return (
    <Modal
      okText={t('action.send')}
      cancelText={t('action.cancel')}
      title={t(title ? `title.${title}` : 'title.reportProblem')}
      open={open}
      onOk={onSend}
      onCancel={onClose}
    >
      <TextArea
        autoFocus
        rows={4}
        maxLength={1000}
        placeholder={t('description.reportProblem')}
        value={text}
        onChange={({ target }) => setText(target.value)}
      />
    </Modal>
  );
}

FeatureRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  bug: PropTypes.bool,
};
