import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import apiProfile from '../api/apiProfile';
import LoginSignupForm from './Common/LoginSignupForm';
import EmailFormItem from './Common/EmailFormItem';
import PasswordFormItem from './Common/PasswordFormItem';
import { addReferrerAndSource } from './Common/utils';

export default function Login({ auth }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [checkEmail, setCheckEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (window.top !== window.self) {
    window.top.location = window.location;
  }

  async function onFinish(payload) {
    setLoading(true);
    if (payload.google_auth_token) addReferrerAndSource(payload);
    if (payload.linkedin_auth_token) addReferrerAndSource(payload);

    try {
      const { data } = await apiProfile.getAuthToken(payload);
      const { confirm_email, access_token } = data;

      if (access_token) {
        auth.setSession(access_token, true);
      } else if (confirm_email) {
        notification.success({ message: t('title.checkInbox') });
        setCheckEmail(payload['email']);
      }

    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  async function onGoogleLogin(token) {
    await onFinish({ google_auth_token: token });
  }

  async function onLinkedinLogin(token) {
    await onFinish({ linkedin_auth_token: token });
  }

  function toSignUp() {
    navigate('/signup');
  }

  function toResetPassword() {
    navigate('/reset-password');
  }

  return <LoginSignupForm
    captcha
    title={t('title.welcome')}
    subTitle={t('description.logIn')}
    loading={loading}
    location={location}
    onFinish={onFinish}
    onGoogleLogin={onGoogleLogin}
    onLinkedinLogin={onLinkedinLogin}
    checkEmail={checkEmail}
    AfterButtonComponent={() => <><Trans i18nKey='description.doNotHaveAccount' /> <Button style={{ marginLeft: -15 }} type='link' onClick={toSignUp}><Trans i18nKey='title.signUp' /></Button></>}
    submitButtonTitle={t('title.logIn')}
  >
    <EmailFormItem />
    <PasswordFormItem />
    <Form.Item>
      <Button type='link' style={{ float: 'right', marginRight: -15 }} onClick={toResetPassword}>
        <Trans i18nKey='title.forgotPassword' />
      </Button>
    </Form.Item>
  </LoginSignupForm>;
}

Login.propTypes = {
  auth: PropTypes.object.isRequired
};
