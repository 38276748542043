import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Result, Col, Row, Button, Divider } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';

import config from 'react-global-configuration';
import PaymentInfo from '../../../shared/Components/PaymentInfo';
import Testimonials from '../../../shared/Components/Testimonials';
import { showConfirm } from '../../../shared/Components/ReasonsToAddCredits';
import AddCredits, { MIN_AMOUNT as minAmount } from '../../Profile/AddCredits/AddCredits';

const StyledButton = styled(Button)`
  border-radius: 4px;
  height: 31px;
`;

export default function TaskConfirmationNotAllowed({ status, onActionCallback, loading, risky = false, depositRequired }) {
  const emailUnverified = status === 'email_unverified' || (localStorage.getItem('emailVerified') !== 'true' && risky);
  const email = localStorage.getItem('email');
  const MIN_AMOUNT = Math.max(depositRequired || minAmount, 1);
  const allowCr = email ? !(email.endsWith('ov') || email.endsWith('du')) : false;

  useEffect(() => {
    if (emailUnverified) return;
    onShowConfirm();
  }, []);

  function onShowConfirm() {
    showConfirm();
  }

  return (
    <Row align='middle' justify='center'>
      <Col style={{ maxWidth: 600 }}>
        <Result
          icon={emailUnverified && <FontAwesomeIcon size='4x' color='#d1cfcf' icon={faEnvelope}/>}
          extra={
            status === 'open_invoices' ? <>
              <h4><Trans i18nKey='title.closeInvoices' />.</h4><br/>
              <p>
                <Button
                  style={{ borderRadius: '4px' }}
                  type='primary'
                  href='/invoices'
                  target='_blank'
                  rel='noopener noreferrer'
                ><Trans i18nKey='action.viewInvoices' /></Button>
              </p>
            </> : emailUnverified ? <h4><Trans i18nKey='title.verifyEmail' /><Trans i18nKey='dot'>.</Trans></h4> : <>
              <AddCredits
                secondaryTab
                emailVerified={!emailUnverified}
                crypto={allowCr}
                defaultValue={10}
                loading={loading}
                riskLevel={risky ? 3 : 1}
                source='task confirmation'
                callback={onActionCallback}
                minValue={MIN_AMOUNT}
              />
              <br/><br/><br/>

              <p>
                <p className='help-p2'>
                  * <Trans i18nKey='description.usingCreditsAfterFree'>Credits are utilized only after exceeding the <a href={`${config.get('pricingUrl')}#free`} target='_blank' rel='noopener noreferrer'>free tier</a>. Minimum deposit: ${{ MIN_AMOUNT }}</Trans>.
                  <br/>
                  * <Trans i18nKey='description.usingCredits'>Use your deposit for any services at listed <a href={`${config.get('pricingUrl')}`} target='_blank' rel='noopener noreferrer'>prices</a>, with no expiration</Trans>.
                  <br/>
                </p>
                {!risky && <>
                  <Divider plain><Trans i18nKey='title.or' /></Divider>
                  <StyledButton
                    icon={<CreditCardOutlined />}
                    type='primary'
                    href='/billing-info?checkout=true'
                    onClick={onActionCallback}
                    target='_blank'
                    rel='noopener noreferrer'
                  >&nbsp;<Trans i18nKey='action.connectCard' /></StyledButton>
                  <br/><br/>
                  <p className='help-p2'>
                    * <Trans i18nKey='description.connectCard'>
                      Connect a card and receive invoices only for usage that exceeds the <a href={`${config.get('pricingUrl')}#free`} target='_blank' rel='noopener noreferrer'>free tier</a>
                    </Trans>.
                    <br/>
                    * <Trans i18nKey='description.PCI'>
                      We use a <a target='_blank' rel='noopener noreferrer' href='http://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'>PCI Service Provider Level 1</a> which is the highest grade of payment security
                    </Trans>.
                  </p>
                </>}
                <PaymentInfo />
              </p>
            </>
          }
        />
      </Col>
      {!emailUnverified && <Col flex='auto'><Testimonials/></Col>}
    </Row>
  );
}

TaskConfirmationNotAllowed.propTypes = {
  status: PropTypes.string.isRequired,
  onActionCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  risky: PropTypes.bool,
  depositRequired: PropTypes.number,
};
